(function () {
  'use strict';

  angular
    .module('neo.home.phases.newPhase')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.phases.newPhase', {
        url: '/new-phase',
        templateUrl: 'home/phases/new-phase/new-phase.tpl.html',
        controller: 'NewPhaseCtrl',
        controllerAs: 'vm',
        resolve: {
          townHalls: function (Ajuntaments) {
            return Ajuntaments.query().$promise;
          }
        }
      });
  }
}());
